const font =
  "'Scto Grotesk A', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif"

const colors = {
  light: {
    error: "#ff3b30",
    black: "#000000",
    white: "#ffffff",
    grey1: "#212529",
    grey2: "#4c4f53",
    grey3: "#777a7d",
    grey4: "#a2a4a6",
    grey5: "#cdcfd0",
    grey6: "#f8f9fa",
    primary: "#ff2d55",
    background: "#f8f9fa",
    buttonBackground: "#000000",
    buttonForeground: "#f8f9fa",
    ghost: "#e5e5e6",
  },
  dark: {
    error: "#ff3b30",
    black: "#000000",
    white: "#ffffff",
    grey1: "#f8f9fa",
    grey2: "#cdcfd0",
    grey3: "#a2a4a6",
    grey4: "#777a7d",
    grey5: "#4c4f53",
    grey6: "#212529",
    primary: "#ff375f",
    background: "#121212",
    buttonBackground: "#121212",
    buttonForeground: "#cdcfd0",
    ghost: "#252525",
  },
}

const typography = {
  font,
  largeTitle: `500 4.4rem/5.2rem ${font}`,
  title1: `500 4rem/4.8rem ${font}`,
  title2: `500 2.4rem/3.2rem ${font}`,
  title3: `500 2rem/2.8rem ${font}`,
  headline: `500 1.6rem/2.4rem ${font}`,
  body: `400 1.6rem/2.4rem ${font}`,
  subhead: `400 1.2rem/2rem ${font}`,
  caption: `400 1.2rem/2rem ${font}`,
}

const units = {
  spacer: {
    xxSmall: "0.2rem",
    xSmall: "0.4rem",
    small: "0.8rem",
    medium: "1.6rem",
    large: "3.2rem",
    xLarge: "6.4rem",
    xxLarge: "12.8rem",
  },
  radius: {
    small: "0.4rem",
    medium: "0.8rem",
    large: "1.6rem",
  },
  breakpoint: {
    medium: "431px",
    large: "835px",
  },
  speed: {
    slow: "400ms",
    fast: "200ms",
  },
  maxWidth: "1080px",
}

const variables = {
  colors,
  typography,
  units,
}

export { colors, typography, units }

export default variables
