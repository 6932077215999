import React from "react"
import PropTypes from "prop-types"
import { css } from "@emotion/react"

const Icon = ({ name, inline = false, size }) => {
  const styles = css({
    direction: "ltr",
    fontFamily: "Material Icons",
    fontSize: "inherit",
    fontWeight: "normal",
    letterSpacing: "normal",
    lineHeight: "1",
    textTransform: "none",
    whiteSpace: "nowrap",
    wordWrap: "normal",
    WebkitFontSmoothing: "antialiased",
    textRendering: "optimizeLegibility",
    MozOsxFontSmoothing: "grayscale",
    fontFeatureSettings: "liga",
  })

  const inlineStyles = {
    display: !inline && "block",
    fontSize: size !== undefined && `${Number(size) * 0.1}rem`,
  }

  return (
    <span style={inlineStyles} css={styles}>
      {name}
    </span>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  inline: PropTypes.bool,
  size: PropTypes.string,
}

export default Icon
