import { useState, useEffect } from "react"
import PropTypes from "prop-types"

const useMedia = (query) => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const onMediaChange = () => {
      setMatches(mediaQueryList.matches)
    }
    const mediaQueryList = window.matchMedia(query)
    onMediaChange()
    mediaQueryList.addListener(onMediaChange)

    return () => {
      mediaQueryList.removeListener(onMediaChange)
    }
  }, [query])

  return matches
}

useMedia.propTypes = {
  query: PropTypes.string.isRequired,
}

export default useMedia
