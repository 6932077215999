import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import Icon from "./Icon"
import { css } from "@emotion/react"
import { colors as theme, units } from "./variables"
import useTheme from "../hooks/useTheme"

const Anchor = ({
  href,
  target = "_self",
  type = "secondary",
  external = false,
  children,
}) => {
  const isDarkTheme = useTheme()
  const colors = isDarkTheme ? theme.dark : theme.light
  const shouldShowOrnament =
    type === "primary" || (external === true && target === "_blank")
  const ornamentLabel = external ? "arrow_outward" : "home"

  let Wrapper
  let props

  if (target === "undefined" || target === "_self") {
    Wrapper = Link
    props = {
      to: href,
    }
  } else {
    Wrapper = OutboundLink
    props = {
      href,
      target,
      rel: "noreferrer",
    }
  }

  const handleClick = (event) => {
    event.target.blur()
    event.target.parentNode.blur()

    if (window && window.gtag) {
      window.gtag("event", "click", {
        event_category: "Anchor",
        event_label: event.target.innerText,
      })
    }
  }

  const styles = {
    root: css({
      color: "inherit",
      display: "inline-block",
      fill: "inherit",
      outline: "none",
      textDecoration: "none",
      transitionDuration: units.speed.fast,
      whiteSpace: "nowrap",

      "&:hover": {
        color: colors.primary,
        fill: colors.primary,
      },

      "&:focus": {
        color: colors.primary,
        fill: colors.primary,
      },
    }),
    primary: css({
      color: colors.primary,
      fill: colors.primary,
      fontWeight: "500",
      marginRight: units.spacer.large,
    }),
    secondary: css({
      color: "inherit",
    }),
    underline: css({
      textDecoration: "underline",
    }),
    ornament: css({
      marginLeft: units.spacer.xSmall,
    }),
  }

  return (
    <Wrapper {...props} css={[styles.root, styles[type]]} onClick={handleClick}>
      <span css={type === "secondary" && styles.underline}>{children}</span>
      {shouldShowOrnament && (
        <span css={styles.ornament}>
          <Icon inline={true} name={ornamentLabel} size="13.6" />
        </span>
      )}
    </Wrapper>
  )
}

Anchor.propTypes = {
  href: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["primary", "secondary"]),
  target: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  children: PropTypes.node.isRequired,
  displayAsButton: PropTypes.bool,
}

export default Anchor
