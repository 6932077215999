import React, { useState } from "react"
import { Instagram, LinkedIn } from "./SocialIcons"
import { Link } from "gatsby"
import { OutboundNavigationLink } from "./NavigationLink"
import { useScrollPosition } from "../hooks/useScrollPosition"
import { css } from "@emotion/react"
import { colors as theme, typography, units } from "./variables"
import { rgba } from "emotion-rgba"
import useMedia from "../hooks/useMedia"
import useTheme from "../hooks/useTheme"

const Logo = () => {
  const isDarkTheme = useTheme()
  const colors = isDarkTheme ? theme.dark : theme.light
  const avatarSize = "3.2rem"

  const styles = {
    root: css({
      alignItems: "center",
      color: colors.grey1,
      display: "flex",
      lineHeight: "3.2rem",
      textDecoration: "none",
      whiteSpace: "nowrap",
    }),
    image: css({
      borderRadius: "50%",
      height: avatarSize,
      marginRight: units.spacer.small,
      outline: "none",
      width: avatarSize,
      display: "block",
    }),
  }

  return (
    <Link to="/" css={styles.root} tabIndex="-1">
      <img
        css={styles.image}
        src="/images/me.png"
        alt="Marko Masnjak - Staff Product Designer"
      />
      Marko Masnjak
    </Link>
  )
}

const NavigationItems = () => {
  const isMediumScreen = useMedia(`(min-width: ${units.breakpoint.medium})`)
  const isResumeVisible = false

  const styles = {
    root: css({
      alignItems: "center",
      boxSizing: "border-box",
      display: "flex",
      justifyContent: "flex-end",
      listStyle: "none",
      margin: "0",
      maxWidth: "100%",
      padding: "0",
    }),
    option: css({
      marginLeft: units.spacer.xSmall,
      marginRight: units.spacer.xSmall,
    }),
    last: css({ marginRight: "0" }),
  }

  return (
    <ul css={styles.root}>
      {isMediumScreen && isResumeVisible && (
        <li css={styles.option}>
          <OutboundNavigationLink
            to="https://drive.google.com/file/d/1mc4qdEDiJWrRFFJVbRWy-_hhNZL8D_D2/view"
            target="_blank">
            Resume
          </OutboundNavigationLink>
        </li>
      )}

      <li css={styles.option}>
        <OutboundNavigationLink
          to="https://www.instagram.com/markomasnjak"
          ariaLabel="Instagram">
          <Instagram size="20" />
        </OutboundNavigationLink>
      </li>

      <li css={[styles.option, styles.last]}>
        <OutboundNavigationLink
          to="https://linkedin.com/in/markomasnjak"
          ariaLabel="LinkedIn">
          <LinkedIn size="20" />
        </OutboundNavigationLink>
      </li>
    </ul>
  )
}

const Navigation = () => {
  const isDarkTheme = useTheme()
  const colors = isDarkTheme ? theme.dark : theme.light
  const [isScrolled, setIsScrolled] = useState(false)

  useScrollPosition(
    ({ currPos }) => {
      setIsScrolled(currPos.y < 0)
    },
    [isScrolled]
  )

  const styles = {
    root: css({
      alignItems: "center",
      backgroundColor: isScrolled ? colors.background : "transparent",
      border: "none",
      boxSizing: "border-box",
      display: "flex",
      fontSize: "1.4rem",
      fontWeight: "500",
      justifyContent: "space-between",
      paddingBottom: units.spacer.medium,
      paddingLeft: "4vw",
      paddingRight: "4vw",
      paddingTop: units.spacer.medium,
      position: "sticky",
      top: "0",
      width: "100%",
      zIndex: "100",

      "@supports (backdrop-filter: blur(2rem)) or (-webkit-backdrop-filter: blur(2rem))":
        {
          backgroundColor: isScrolled
            ? rgba(colors.background, 0.8)
            : "transparent",
          backdropFilter: "saturate(180%) blur(2rem)",
          webkitBackdropFilter: "saturate(180%) blur(2rem)",
        },
    }),

    left: css({ flexGrow: "1", marginRight: units.spacer.large }),
    right: css({
      display: "flex",
      justifyContent: "flex-end",
    }),
    logo: css({
      font: typography.headline,
      lineHeight: "3.2rem",
      textDecoration: "none",
      color: colors.grey1,
    }),
  }

  return (
    <nav id="top" css={[styles.root]}>
      <div css={styles.left}>
        <Logo />
      </div>

      <div css={styles.right}>
        <NavigationItems />
      </div>
    </nav>
  )
}

export default Navigation
