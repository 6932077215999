import { useState, useEffect } from "react"

const useTheme = () => {
  const [matches, setMatches] = useState(false)

  useEffect(() => {
    const onMediaChange = () => {
      setMatches(mediaQueryList.matches)
    }

    const mediaQueryList = window.matchMedia("(prefers-color-scheme: dark)")
    onMediaChange()
    mediaQueryList.addListener(onMediaChange)

    return () => {
      mediaQueryList.removeListener(onMediaChange)
    }
  }, [])

  return matches
}

export default useTheme
